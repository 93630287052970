import moment from 'moment'

/**
 * Returns the value at path of the given object, if the value
 * is null or undefined defayltVal will be returned instead
 * @param {*} path
 * @param {*} obj
 * @param {*} defaultVal
 */
export function objectGet(path, obj, defaultVal = null) {
  return path.split('.').reduce((obj, path) => {
    return obj ? obj[path] : defaultVal
  }, obj)
}

export function formatNullable(value) {
  if (typeof value === `number`)
    return Math.ceil(value)

  if (!value && value !== 0)
    return `N/A`

  return value
}

export function formatDate(date, format = 'l') {
  const d = moment(date)
  if (!d.isValid())
    return formatNullable(date)

  return d.format(format)
}

/**
 * @description formats number based in the format (xxx) xxx-xxx
 * Response from stackoverflow
 * @url https://stackoverflow.com/a/8358141/8392469
 * @param phoneNumberString
 * @returns {*}
 */

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '')
  const padded = cleaned.padEnd(10, '*')

  // Split the padded number into parts
  const part1 = padded.slice(0, 3)
  const part2 = padded.slice(3, 6)
  const part3 = padded.slice(6, 10)

  return `(${part1}) ${part2} ${part3}`
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function parseRawNumber(value) {
  return Number.parseFloat((value * 0.01).toFixed(2))
}

export function formatMoney(value) {
  return parseRawNumber(value).toLocaleString('US', {
    style: 'currency',
    currency: 'USD',
  })
}

export function isNullOrUndefined(item) {
  return item === undefined || item === null
}

export const clone = object => JSON.parse(JSON.stringify(object))
