export function state() {
  return {
    items: [],
  }
}

export const mutations = {
  setItems(state, data) {
    state.items = data
  },
}

export const actions = {
  async fetchItems({ commit }, params) {
    try {
      return await this.$axios
        .$get(`/reports`, {
          params,
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        })
        .then((data) => {
          data.data.totalPages = data.meta.total
          commit('setItems', data.data)
          return Promise.resolve(state.items)
        })
    }
    catch {}
  },
  downloadCsv(_, item) {
    const urlKey = (item.key === 'incoming-orders' || item.key === 'outgoing-orders') ? 'sales-orders' : item.key

    this.$axios
      .$post(`/export/${urlKey}`, item, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      }).then((data) => {
        this.$notify({
          type: 'info',
          title: 'Downloading...',
          text: 'Please wait while we download the report.',
        })

        const filename = `${item.key}-${item.from}-${item.to}.csv`

        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
  },

  async printReportReceipt({ rootState }, item) {
    await this.$axios
      .$post(`/receipt/daily-summary`, {
        ...item,
        currentTerminal: rootState.terminals.selected.id,
      }, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })

    this.$notify({
      type: 'success',
      title: 'Success',
      text: 'Print job created',
    })
  },

}
