import axios from 'axios'

export default function ({ $axios, store, redirect }) {
  let isLoggedOut = false
  let cancelTokenSource = axios.CancelToken.source()

  // Function to reset the logout state
  function resetLogoutState() {
    isLoggedOut = false
    cancelTokenSource = axios.CancelToken.source() // Create a new cancel token
  }

  $axios.interceptors.request.use((config) => {
    // Check if the request is a login request
    if (config.url.includes('/login') && config.method === 'post') {
      resetLogoutState() // Reset the logout state on login request
    }

    if (isLoggedOut) {
      // Cancel the request if the user is logged out
      config.cancelToken = cancelTokenSource.token
      cancelTokenSource.cancel('Request canceled due to logout.')
    }
    else {
      const companyId = localStorage.getItem('company-id')
      const token = store.$auth.getToken('local')

      if (companyId) {
        config.headers.common['X-Company-Id'] = companyId
      }

      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`
      }
    }

    return config
  })

  $axios.onError((error) => {
    const code = Number.parseInt(error.response?.status)

    if (code === 401 && store.$auth) {
      if (!isLoggedOut) {
        isLoggedOut = true
        store.$auth.logout()
        redirect('/login')
      }
    }

    if (code === 403) {
      redirect('/location-select')
    }
  })
}
